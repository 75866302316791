import { LoadingButton } from '@mui/lab';
import {
  Alert,
  AlertTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import type { Course, Event } from 'core';
import type { FC } from 'react';
import { sortByCourseTitle } from '../../utils/course-sort.js';

interface Props {
  busy: boolean;
  event: Event.WithCourses;
  onConnectCourse: (course: Course.Type) => void;
  onCourseIdSelected: (courseId: string) => void;
  selectedCourse: Course.Type | undefined;
}

export const NoCoursesView: FC<{ event: Event.WithCourses }> = ({ event }) => (
  <Alert severity="info">
    <AlertTitle>Waiting for Course Upload</AlertTitle>
    Standby for the event organizers to upload the courses for{' '}
    <strong>{event.title}</strong>. Please check back shortly. Thanks.
  </Alert>
);

export const ListCoursesView: FC<Props> = ({
  busy,
  event,
  onConnectCourse,
  onCourseIdSelected,
  selectedCourse,
}) => (
  <Alert severity="info">
    <AlertTitle>Select a Course</AlertTitle>
    <Stack spacing={1}>
      <Typography>
        This track has matched the event <strong>{event.title}</strong>. Courses
        are not automatically determined.
      </Typography>
      <FormControl>
        <FormLabel id="select-course-radio-group">
          Please select the course that matches this track
        </FormLabel>
        <RadioGroup
          aria-labelledby="select-course-radio-group"
          name="select-course-radio-group"
          value={selectedCourse?._id ?? ''}
          onChange={(evt) => {
            onCourseIdSelected((evt.target as HTMLInputElement).value);
          }}
        >
          {event.courses.sort(sortByCourseTitle).map((course) => (
            <FormControlLabel
              key={course._id}
              value={course._id}
              control={<Radio />}
              label={course.title}
            />
          ))}
        </RadioGroup>
      </FormControl>
      <LoadingButton
        disabled={!selectedCourse}
        loading={!!busy}
        onClick={() => selectedCourse && onConnectCourse(selectedCourse)}
        variant="outlined"
      >
        Update
      </LoadingButton>
    </Stack>
  </Alert>
);
