import { Alert, AvatarGroup, Button, Skeleton, Stack } from '@mui/material';
import type { Course, Event } from 'core';
import type { FC } from 'react';
import uri from 'uri-tag';
import { PersonAvatar } from '../../components/entity/person.js';
import { InfoFieldView } from '../../components/info-field/info-field.js';
import type { UsePromiseResult } from '../../utils/promise.js';

interface Props {
  eventCourse: UsePromiseResult<
    | {
        event: Event.Type | undefined;
        course: Course.WithTrackSummary | undefined;
      }
    | undefined
  >;
}

export const TrackEventView: FC<Props> = ({
  eventCourse: [eventCourse, error, state],
  ...props
}) =>
  state === 'pending' ? (
    <Skeleton variant="rounded" height={60} width="100%" />
  ) : state === 'rejected' ? (
    <Alert severity="error">{error?.message}</Alert>
  ) : eventCourse?.event ? (
    <TrackEventSummary
      {...props}
      event={eventCourse.event}
      course={eventCourse.course}
    />
  ) : (
    <></>
  );

const TrackEventSummary: FC<
  Omit<Props, 'eventCourse'> & {
    event: Event.Type;
    course: Course.WithTrackSummary | undefined;
  }
> = ({ event, course }) =>
  course ? (
    <Stack spacing={3}>
      <InfoFieldView label="Course">{`${event.title} - ${course.title}`}</InfoFieldView>
      <Button
        href={uri`#/event/${event._id}/course/${course._id}/tracks`}
        variant="outlined"
        size="medium"
        sx={{ ml: '1rem' }}
      >
        Compare Tracks
        {course.trackCount ? (
          <AvatarGroup
            total={course.trackPersonIds.length}
            sx={{
              ml: '0.5em',
              '.MuiAvatarGroup-avatar': {
                fontSize: '10px',
                height: '16px',
                ml: 0,
                width: '16px',
              },
            }}
          >
            {course.trackPersonIds.slice(0, 10).map((personId) => (
              <PersonAvatar key={personId} personId={personId} size="16px" />
            ))}
          </AvatarGroup>
        ) : null}
      </Button>
    </Stack>
  ) : (
    <InfoFieldView label="Event">{event.title}</InfoFieldView>
  );
