import { useTheme } from '@mui/material';
import { captureException } from '@sentry/react';
import { bbox } from '@turf/turf';
import axios from 'axios';
import type { Course, DataPoint, Track } from 'core';
import {
  bboxToBounds,
  combineBbox,
  computeVisitTimes,
  controlsToGeometry,
  flattenLocationSegments,
  getEventScale,
  isRogaine,
  linearMatch,
  resolveControls,
  rogaineMatch,
  segmentsToLineString,
  toLegs,
} from 'core';
import { useSnackbar } from 'notistack';
import type { FC } from 'react';
import { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import usePromise from 'react-use-promise';
import uri from 'uri-tag';
import { useAuthenticated } from '../../auth/auth-provider.js';
import { MapStyles } from '../../components/map/map-styles.js';
import { MiddleSpinner } from '../../components/spinner/spinner.js';
import { useAxios } from '../../hooks/use-axios.js';
import { useBoolFlag } from '../../hooks/use-flag.js';
import { useCache } from '../../providers/cache.js';
import { ErrorPage } from '../error/page.js';
import { NotFoundPage } from '../not-found/page.js';
import { TrackPageView } from './view.js';

const Controller: FC = () => {
  const { person } = useAuthenticated();
  const { id } = useParams();
  const theme = useTheme();
  const cache = useCache();
  const [edit, setEdit] = useState(false);
  const [coursePreview, setCoursePreview] = useState<Course.Type | undefined>();
  const trackReprocess = useBoolFlag('track.reprocess');
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [track, refresh] = useAxios<Track.Type>(id && uri`/api/v1/track/${id}`);

  const eventCourse = usePromise(async () => {
    if (!track.data || !track.data.eventId) return undefined;
    const event = await cache.event.get(track.data.eventId);
    const course =
      event && track.data.courseId
        ? event.courses.find((course) => course._id === track.data?.courseId)
        : undefined;

    return {
      event,
      course,
    };
  }, [track.data]);

  const course = coursePreview ?? eventCourse[0]?.course;
  const event = eventCourse[0]?.event;
  const controls = useMemo(
    () =>
      course && event
        ? resolveControls(course.controls, event.controls, event.type)
        : [],
    [course, event],
  );

  const splitAnalysis = usePromise(async () => {
    const course = eventCourse[0]?.course; // not preview
    if (!id) return undefined;

    const { data: segments } = await axios.get<DataPoint[][]>(
      uri`/api/v1/track/${id}/points`,
    );

    const geometry = segmentsToLineString(segments);
    const bounds = bbox(geometry);

    if (!event || !course || !controls?.length) return { bounds, geometry };

    const locations = flattenLocationSegments(segments);
    const vistiTimes = computeVisitTimes(
      locations,
      controls,
      event.type,
      course.defaultControlRadius ?? event.defaultControlRadius,
    );
    const visits = isRogaine(event.type)
      ? rogaineMatch(vistiTimes, controls)
      : linearMatch(vistiTimes, controls, track.data?.skippedFirstControls);

    const legs = toLegs(visits, locations);
    return { bounds, geometry, analysis: { legs, visits } };
  }, [track.data, event, eventCourse[0]?.course, controls]);

  if (track.loading) return MiddleSpinner({});
  if (track.error) return ErrorPage({ error: track.error });
  if (!track.data) return NotFoundPage({});

  const boundsArray = splitAnalysis[0]?.bounds
    ? [splitAnalysis[0]?.bounds]
    : [];
  if (controls?.length) {
    boundsArray.push(
      bbox(controlsToGeometry(controls, getEventScale(event?.type))),
    );
  }

  const bounds = boundsArray.length ? combineBbox(boundsArray) : undefined;

  const actions = {
    changeCourse: () => {
      if (!track.data) return;

      axios
        .post(uri`/api/v1/track/${track.data._id}/set-course`, {
          eventId: track.data.eventId,
          courseId: null,
        })
        .then(() => {
          refresh();
          return;
        })
        .catch((err) => {
          captureException(err);
          enqueueSnackbar('Unable to update track', { variant: 'error' });
          refresh();
        });
    },
    deleteTrack: () => {
      if (!track.data) return;

      axios
        .delete(uri`/api/v1/track/${track.data._id}`)
        .then(() => {
          navigate('/', { replace: true });
          return;
        })
        .catch((err) => {
          captureException(err);
          enqueueSnackbar('Unable to delete track', { variant: 'error' });
          refresh();
        });
    },
    rename: () => setEdit(true),
  };

  return TrackPageView({
    actions,
    bounds: bounds ? bboxToBounds(bounds) : undefined,
    canEdit: track.data.personId === person._id,
    course,
    coursePreview,
    edit,
    eventCourse,
    geometry: splitAnalysis[0]?.geometry,
    mapStyle: MapStyles.SATELLITE,
    myTrack: track.data.personId === person._id,
    onReprocessClicked: trackReprocess
      ? (track) => {
          axios
            .post(uri`/api/v1/track/${track._id}/reprocess`)
            .catch(captureException);
        }
      : undefined,
    refresh: () => {
      setEdit(false);
      refresh();
    },
    setCoursePreview,
    splitAnalysis: splitAnalysis[0]?.analysis,
    track: track.data,
    trackColor: theme.palette.primary.main,
  });
};

export default Controller;
