import { captureException } from '@sentry/react';
import axios from 'axios';
import type { Course, Event } from 'core';
import { Track } from 'core';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import type { FC } from 'react';
import uri from 'uri-tag';
import type { ExistingEntityViewProps } from '../../../utils/types.js';
import { formikCheck } from '../../../utils/validation.js';
import { EditDetailsView } from './edit-details.jsx';

interface Props extends ExistingEntityViewProps<Track.Type> {
  event?: Event.Type;
  course?: Course.Type;
}

export const EditDetails: FC<Props> = ({
  id,
  course,
  data,
  event,
  refresh,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const formik = useFormik<Track.Patch>({
    initialValues: {
      title: data.title,
      tags: data.tags,
      labels: data.labels,
      teamName: data.teamName ?? null,
    },
    validate: async (value) => formikCheck(Track.patchContract, value),
    validateOnBlur: true,
    onReset: refresh,
    onSubmit: async (body) => {
      try {
        await axios.patch(uri`/api/v1/track/${id}`, body);
        enqueueSnackbar('Track updated', { variant: 'success' });
      } catch (err: unknown) {
        captureException(err);
        if (err instanceof Error) {
          enqueueSnackbar(`Unable to update: ${err.message}`, {
            variant: 'error',
          });
        } else {
          throw err;
        }
      }
      refresh();
    },
  });

  const suggestTitle = (): void => {
    const title = event
      ? course
        ? `${event.title} - ${course.title}`
        : event.title
      : '';
    formik.setFieldValue('title', title).catch(captureException);
  };

  return EditDetailsView({
    canSuggestTitle: !!event,
    formik,
    onSuggestTitleClick: suggestTitle,
  });
};
