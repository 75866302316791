import {
  NorthEast as AltitudeUpIcon,
  Straighten as DistanceIcon,
  AccessAlarm as TimeIcon,
} from '@mui/icons-material';
import {
  Box,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
  colors,
} from '@mui/material';
import type { Course, LegWithStats, MissedVisit, VisitTime } from 'core';
import { isVisit } from 'core';
import type { FC } from 'react';
import { Fragment } from 'react';
import { dateDiffSeconds } from '../../utils/date.js';
import {
  formatAltitude,
  formatMeters,
  formatSeconds,
} from '../../utils/format.js';

interface Props {
  course: Course.Type;
  splitAnalysis: { legs: LegWithStats[]; visits: (VisitTime | MissedVisit)[] };
}

export const SplitAnalysisView: FC<Props> = ({ splitAnalysis: { legs } }) => (
  <Card>
    <CardContent>
      <Box display="flex" flex={1} justifyContent="space-between">
        <Typography variant="h3">Stats</Typography>
      </Box>
    </CardContent>

    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Time</TableCell>
            <TableCell>Distance</TableCell>
            <TableCell>Altitude Gain</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {legs.map((leg, index) => (
            // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
            <Fragment key={index}>
              {leg.from && index > 0 ? (
                isVisit(leg.from) ? (
                  leg.from.departed ? (
                    <TableRow sx={{ fontStyle: 'italic' }}>
                      <TableCell>
                        At control{' '}
                        <strong>{index === 1 ? 'Start' : index - 1}</strong> (
                        <Box component="strong" sx={{ color: '#A626FF' }}>
                          {leg.from.control._id}
                        </Box>
                        )
                      </TableCell>
                      <TableCell>
                        <TimeIcon
                          sx={{
                            height: '0.8rem',
                            mr: 0.5,
                            width: '0.8rem',
                            color: colors.grey[500],
                            verticalAlign: 'middle',
                          }}
                        />
                        {formatSeconds(
                          dateDiffSeconds(leg.from.departed, leg.from.arrived),
                        )}
                      </TableCell>
                      <TableCell />
                      <TableCell />
                    </TableRow>
                  ) : undefined
                ) : (
                  <TableRow sx={{ fontStyle: 'italic' }}>
                    <TableCell>
                      Missed control{' '}
                      <strong>{index === 1 ? 'Start' : index - 1}</strong> (
                      <Box component="strong" sx={{ color: '#A626FF' }}>
                        {leg.from.control._id}
                      </Box>
                      )
                    </TableCell>
                    <TableCell />
                    <TableCell />
                    <TableCell />
                  </TableRow>
                )
              ) : undefined}
              {leg.from && isVisit(leg.from) && leg.to && isVisit(leg.to) ? (
                <TableRow>
                  <TableCell sx={{ textAlign: 'right' }}>
                    <Typography variant="body2">
                      From{' '}
                      <strong>
                        {leg.from?.control.type === 'start'
                          ? 'Start'
                          : index - 1}
                      </strong>{' '}
                      to{' '}
                      <strong>
                        {leg.to?.control.type === 'finish' ? 'Finish' : index}
                      </strong>
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      fontStyle="italic"
                      fontSize="0.85em"
                    >
                      ({leg.from?.control._id ?? '?'} -{' '}
                      {leg.to?.control._id ?? '?'})
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <TimeIcon
                      sx={{
                        height: '0.8rem',
                        mr: 0.5,
                        width: '0.8rem',
                        color: colors.grey[500],
                        verticalAlign: 'middle',
                      }}
                    />
                    {formatSeconds(leg.seconds) ?? '-'}
                  </TableCell>
                  <TableCell>
                    <DistanceIcon
                      sx={{
                        height: '0.8rem',
                        mr: 0.5,
                        width: '0.8rem',
                        color: colors.grey[500],
                        verticalAlign: 'middle',
                      }}
                    />
                    {formatMeters(leg.stats.meters) ?? '-'}
                  </TableCell>
                  <TableCell>
                    <AltitudeUpIcon
                      sx={{
                        height: '0.8rem',
                        mr: 0.5,
                        width: '0.8rem',
                        color: colors.grey[500],
                        verticalAlign: 'middle',
                      }}
                    />
                    {formatAltitude(leg.stats.altitude.increase) ?? '-'}
                  </TableCell>
                </TableRow>
              ) : undefined}
            </Fragment>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell />
            <TableCell>Time</TableCell>
            <TableCell>Distance</TableCell>
            <TableCell>Altitude Gain</TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  </Card>
);
