import {
  ArrowBack as CancelIcon,
  Save as SaveIcon,
  AutoAwesome as SuggestIcon,
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  CardActions,
  CardContent,
  Grid,
  IconButton,
  InputAdornment,
} from '@mui/material';
import type { Track } from 'core';
import type { FormikProps } from 'formik';
import type { FC } from 'react';
import { FormikTextField } from '../../../components/formik-fields/text-field.jsx';

interface Props {
  canSuggestTitle: boolean;
  formik: FormikProps<Track.Patch>;
  onSuggestTitleClick: () => void;
}

export const EditDetailsView: FC<Props> = ({
  canSuggestTitle,
  formik,
  onSuggestTitleClick,
}) => (
  <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
    <CardContent>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <FormikTextField
            id="title"
            label="Track Title"
            formik={formik}
            autoFocus={true}
            fullWidth
            required
            endAdornment={
              canSuggestTitle ? (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="suggest title"
                    onClick={() => onSuggestTitleClick()}
                    edge="end"
                  >
                    <SuggestIcon />
                  </IconButton>
                </InputAdornment>
              ) : undefined
            }
          />
        </Grid>
      </Grid>
    </CardContent>

    <CardActions>
      <LoadingButton
        color="primary"
        loading={formik.isSubmitting}
        onClick={formik.handleReset}
        startIcon={<CancelIcon />}
        variant="outlined"
      >
        Cancel
      </LoadingButton>
      <LoadingButton
        color="primary"
        loading={formik.isSubmitting}
        startIcon={<SaveIcon />}
        type="submit"
        variant="contained"
      >
        Save
      </LoadingButton>
    </CardActions>
  </form>
);
