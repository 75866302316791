import { captureException } from '@sentry/react';
import axios from 'axios';
import type { Event, Track } from 'core';
import { useSnackbar } from 'notistack';
import type { FC } from 'react';
import { useState } from 'react';
import uri from 'uri-tag';
import {
  ConnectMatchingEventView,
  NoMatchingEventView,
} from './matching-event.view.js';
import { withMatchingEvents } from './with-matching-events.js';

interface Props {
  refresh: () => void;
  track: Track.Type;
}

export const ConnectMatchingEvent: FC<Props> = withMatchingEvents(
  ({ matchingEvents: events, refresh, ...props }) => {
    const [selectedEvent, setSelectedEvent] = useState<
      Event.Type | undefined
    >();
    const [busy, setBusy] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const onConnectEventClick = (event: Event.Type): void => {
      setBusy(true);
      axios
        .post(uri`/api/v1/track/${props.track._id}/set-event`, {
          eventId: event._id,
        })
        .then(() => {
          enqueueSnackbar(
            'Connected to this event. Now please select a course.',
            { variant: 'success' },
          );
          refresh();
          return;
        })
        .catch((error) => {
          enqueueSnackbar('Unable to connect to this event.', {
            variant: 'error',
          });
          captureException(error);
          setBusy(false);
        });
    };

    if (!events.length) return NoMatchingEventView({});

    return ConnectMatchingEventView({
      ...props,
      busy,
      events,
      onConnectEventClick,
      onEventIdSelected: (id) =>
        setSelectedEvent(events.find((event) => event._id === id)),
      selectedEvent,
    });
  },
);
