import {
  SwapHoriz as ChangeCourseIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  MoreVert as MoreIcon,
} from '@mui/icons-material';
import {
  Alert,
  Box,
  Card,
  CardContent,
  Collapse,
  Container,
  Divider,
  IconButton,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import type {
  Bounds,
  Course,
  Event,
  LegWithStats,
  MissedVisit,
  Track,
  VisitTime,
} from 'core';
import 'mapbox-gl/dist/mapbox-gl.css';
import type { FC } from 'react';
import { Layer, Source } from 'react-map-gl';
import {
  InfoFieldStack,
  InfoFieldView,
} from '../../components/info-field/info-field.js';
import { Map as MapControl } from '../../components/map/component.js';
import { CourseLayer } from '../../components/map/layers/course.js';
import { OverlayImageLayer } from '../../components/map/layers/overlay-image.js';
import type { ConfirmMenuItemProps } from '../../components/menu-controller/menu-controller.js';
import { MenuController } from '../../components/menu-controller/menu-controller.js';
import { OriginalFormat } from '../../components/original-format/view.js';
import { PageTitle } from '../../components/page-title/page-title.js';
import {
  formatDate,
  formatMeters,
  formatSeconds,
  formatSecondsPerMeter,
} from '../../utils/format.js';
import type { UsePromiseResult } from '../../utils/promise.js';
import { EditDetails } from './edit/controller.js';
import { TrackEventView } from './event.js';
import { ConnectMatchingEvent } from './matching-event.js';
import { SelectEventCourse } from './select-course.js';
import { SplitAnalysisView } from './split-analysis.js';

type ActionNames = 'rename' | 'changeCourse' | 'deleteTrack';

export interface SplitAnalysisViewData {
  legs: LegWithStats[];
  visits: (VisitTime | MissedVisit)[];
}

interface Props {
  actions: Record<ActionNames, () => void>;
  bounds: Bounds | undefined;
  canEdit: boolean;
  course: Course.Type | undefined;
  coursePreview: Course.Type | undefined; // TODO not used
  edit: boolean;
  eventCourse: UsePromiseResult<
    | {
        event: Event.WithCourses | undefined;
        course: Course.WithTrackSummary | undefined;
      }
    | undefined
  >;
  geometry: GeoJSON.FeatureCollection | undefined;
  mapStyle: string;
  myTrack: boolean;
  onReprocessClicked?: (track: Track.Type) => void;
  refresh: () => void;
  setCoursePreview: (course: Course.Type | undefined) => void;
  splitAnalysis: SplitAnalysisViewData | undefined;
  track: Track.Type;
  trackColor: string;
}

export const TrackPageView: FC<Props> = ({
  actions,
  bounds,
  canEdit,
  course,
  edit,
  eventCourse,
  geometry,
  mapStyle,
  myTrack,
  onReprocessClicked,
  refresh,
  setCoursePreview,
  splitAnalysis,
  track,
  trackColor,
}) => (
  <PageTitle title={track.title}>
    <Container maxWidth="md">
      <Stack spacing={3} my={3}>
        {myTrack ? (
          !track.eventId ? (
            <ConnectMatchingEvent refresh={refresh} track={track} />
          ) : (
            <SelectEventCourse
              eventCourse={eventCourse}
              refresh={refresh}
              setCoursePreview={setCoursePreview}
              track={track}
            />
          )
        ) : null}
        <Card sx={{ width: '100%' }}>
          <Collapse in={!edit}>
            <Stack spacing={3} width="100%">
              <CardContent>
                <Stack spacing={1} width="100%">
                  <TrackInfoView
                    canEdit={canEdit}
                    extraMenu={[
                      {
                        key: 'rename',
                        icon: <EditIcon fontSize="small" />,
                        label: 'Rename',
                        onClick: actions.rename,
                      },
                      {
                        key: 'change-course',
                        hidden: !eventCourse[0]?.course,
                        icon: <ChangeCourseIcon fontSize="small" />,
                        label: 'Change Course',
                        onClick: actions.changeCourse,
                      },
                      {
                        key: 'delete',
                        confirm: true,
                        icon: <DeleteIcon fontSize="small" />,
                        label: 'Delete Track',
                        onClick: actions.deleteTrack,
                      },
                    ]}
                    onReprocessClicked={onReprocessClicked}
                    track={track}
                  />
                  <TrackEventView eventCourse={eventCourse} />
                </Stack>
              </CardContent>
              {geometry && bounds ? (
                <MapControl height={460} bounds={bounds} mapStyle={mapStyle}>
                  {eventCourse[0]?.event?.overlayImage ? (
                    <OverlayImageLayer
                      corners={eventCourse[0].event.overlayImage.corners}
                      imageUrl={`/api/v1/event/${eventCourse[0].event._id}/${eventCourse[0].event.overlayImage.path}`}
                      interpolateOpacity={true}
                    />
                  ) : null}
                  {course && eventCourse[0]?.event ? (
                    <CourseLayer course={course} event={eventCourse[0].event} />
                  ) : undefined}
                  <Source id="track-data" type="geojson" data={geometry}>
                    <Layer
                      id="track-layer"
                      type="line"
                      paint={{
                        'line-color': trackColor,
                        'line-width': 2.5,
                      }}
                    />
                  </Source>
                </MapControl>
              ) : (
                <Skeleton variant="rounded" height={60} width="100%" />
              )}
            </Stack>
          </Collapse>
          <Collapse in={edit}>
            <EditDetails
              id={track._id}
              course={eventCourse[0]?.course}
              data={track}
              event={eventCourse[0]?.event}
              refresh={refresh}
            />
          </Collapse>
        </Card>
        {eventCourse[0]?.course && splitAnalysis ? (
          <>
            <SplitAnalysisView
              course={eventCourse[0]?.course}
              splitAnalysis={splitAnalysis}
            />
            <Alert severity="info">
              Timings are generated from GPS proximity to controls and do not
              represent official timings of the event.
            </Alert>
          </>
        ) : undefined}
      </Stack>
    </Container>
  </PageTitle>
);

export const TrackInfoView: FC<{
  canEdit: boolean;
  extraMenu: ConfirmMenuItemProps[];
  onReprocessClicked?: (track: Track.Type) => void;
  track: Track.Type;
}> = ({ canEdit, extraMenu, onReprocessClicked, track }) => (
  <Stack spacing={1} sx={{ alignItems: 'stretch', flex: 1 }}>
    <Box display="flex" flex={1} justifyContent="space-between">
      <Stack spacing={1} direction="row">
        <Typography variant="h3">{track.title}</Typography>
      </Stack>
      <Stack spacing={1} direction="row">
        <Box sx={{ mt: '2px' }}>
          <OriginalFormat
            format={track.originalFormat}
            onClick={
              onReprocessClicked ? () => onReprocessClicked(track) : undefined
            }
          />
        </Box>
        {canEdit ? (
          <MenuController
            button={(handleClick) => (
              <IconButton onClick={handleClick} sx={{ mt: '-4px !important' }}>
                <MoreIcon />
              </IconButton>
            )}
            horizontalAlign="right"
            items={extraMenu}
          />
        ) : null}
      </Stack>
    </Box>
    <InfoFieldStack>
      <InfoFieldView label="Date">{formatDate(track.start.when)}</InfoFieldView>
      <Divider orientation="vertical" sx={{ height: '2rem' }} />
      <InfoFieldView label="Duration">
        {formatSeconds(track.stats.seconds)}
      </InfoFieldView>
      {track.stats.meters ? (
        <>
          <Divider orientation="vertical" sx={{ height: '2rem' }} />
          <InfoFieldView label="Distance">
            {formatMeters(track.stats.meters) ?? '-'}
          </InfoFieldView>
          <Divider orientation="vertical" sx={{ height: '2rem' }} />
          <InfoFieldView label="Pace">
            {formatSecondsPerMeter(
              track.stats.meters
                ? track.stats.seconds / track.stats.meters
                : undefined,
            ) ?? '-'}
          </InfoFieldView>
        </>
      ) : undefined}
    </InfoFieldStack>
  </Stack>
);
