import { captureException } from '@sentry/react';
import axios from 'axios';
import type { Course, Event, Track } from 'core';
import { useSnackbar } from 'notistack';
import type { FC } from 'react';
import { useState } from 'react';
import uri from 'uri-tag';
import type { UsePromiseResult } from '../../utils/promise.js';
import { ListCoursesView, NoCoursesView } from './select-course.view.js';

interface Props {
  eventCourse: UsePromiseResult<
    | { event: Event.WithCourses | undefined; course: Course.Type | undefined }
    | undefined
  >;
  refresh: () => void;
  setCoursePreview: (course: Course.Type | undefined) => void;
  track: Track.Type;
}

export const SelectEventCourse: FC<Props> = ({
  eventCourse: [eventCourse],
  refresh,
  setCoursePreview,
  track,
}) => {
  const [busy, setBusy] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState<
    Course.Type | undefined
  >();
  const { enqueueSnackbar } = useSnackbar();

  if (!eventCourse || !eventCourse.event || eventCourse.course) return null;

  const { courses } = eventCourse.event;

  const onConnectCourse = (course: Course.Type): void => {
    setBusy(true);
    axios
      .post(uri`/api/v1/track/${track._id}/set-course`, {
        eventId: course.eventId,
        courseId: course._id,
      })
      .then(() => {
        refresh();
        setCoursePreview(undefined);
        setBusy(false);
        return;
      })
      .catch((err) => {
        captureException(err);
        enqueueSnackbar('Unable to update track', { variant: 'error' });
        setBusy(false);
      });
  };

  if (!courses.length) return NoCoursesView({ event: eventCourse.event });

  return ListCoursesView({
    busy,
    event: eventCourse.event,
    onConnectCourse,
    onCourseIdSelected: (id) => {
      const course = courses.find((course) => course._id === id);
      setSelectedCourse(course);
      setCoursePreview(course);
    },
    selectedCourse,
  });
};
