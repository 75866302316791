import { Alert } from '@mui/material';
import type { Event, Track } from 'core';
import uri from 'uri-tag';
import { useAuthenticated } from '../../auth/auth-provider.js';
import { useAxios } from '../../hooks/use-axios.js';

interface WithMatchingEvents {
  matchingEvents: Event.WithCourses[];
}

interface Props {
  track: Track.Type;
}

export const withMatchingEvents =
  <P extends Props>(
    Component: React.ComponentType<P & WithMatchingEvents>,
  ): React.FC<P> =>
  (props) => {
    const { person } = useAuthenticated();
    const [matchingTracks] = useAxios<{ items: Event.WithCourses[] }>(
      uri`/api/v1/track/${props.track._id}/matching-events`,
      undefined,
      person._id === props.track.personId && !props.track.eventId,
    );

    if (matchingTracks.loading) return null;
    if (matchingTracks.error)
      return <Alert severity="error">{matchingTracks.error.message}</Alert>;
    if (!matchingTracks.data?.items) return null;

    return <Component {...props} matchingEvents={matchingTracks.data.items} />;
  };
