import { LoadingButton } from '@mui/lab';
import {
  Alert,
  AlertTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import type { Event } from 'core';
import type { FC } from 'react';

interface Props {
  busy: boolean;
  events: Event.Type[];
  selectedEvent: Event.Type | undefined;
  onConnectEventClick: (event: Event.Type) => void;
  onEventIdSelected: (id: string) => void;
}

export const ConnectMatchingEventView: FC<Props> = ({
  busy,
  events,
  onConnectEventClick,
  onEventIdSelected,
  selectedEvent,
}) => (
  <Alert severity="info">
    <AlertTitle>Matching Events</AlertTitle>
    <Stack spacing={1}>
      <Typography>
        This track is not yet connected to an event. We have found{' '}
        {events.length === 1
          ? 'an event that matches'
          : 'some events that match'}
        .
      </Typography>
      <FormControl>
        <FormLabel id="select-track-radio-group">
          Please select an event to connect to this track
        </FormLabel>
        <RadioGroup
          aria-labelledby="select-track-radio-group"
          name="select-track-radio-group"
          value={selectedEvent?._id ?? ''}
          onChange={(evt) => {
            onEventIdSelected((evt.target as HTMLInputElement).value);
          }}
        >
          {events.map((event) => (
            <FormControlLabel
              key={event._id}
              value={event._id}
              control={<Radio />}
              label={event.title}
            />
          ))}
        </RadioGroup>
      </FormControl>
      <LoadingButton
        disabled={!selectedEvent}
        loading={busy}
        onClick={() => selectedEvent && onConnectEventClick(selectedEvent)}
        variant="outlined"
      >
        Connect
      </LoadingButton>
    </Stack>
  </Alert>
);

export const NoMatchingEventView: FC = () => (
  <Alert severity="info">
    <AlertTitle>No Matching Events</AlertTitle>
    <Stack spacing={1}>
      <Typography variant="body2">
        We couldn't find any events for this track. Possible it's still being
        created. Please check back shortly. Thanks.
      </Typography>
    </Stack>
  </Alert>
);
